import React from 'react';

function Notifications() {
  return (
    <div style={{ padding: '20px', color: 'white', textAlign: 'center' }}>
      <h1>Notifications</h1>
      <p>This is your notifications tab.</p>
    </div>
  );
}

export default Notifications;
